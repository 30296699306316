// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-activate-user-jsx": () => import("./../../../src/components/activateUser.jsx" /* webpackChunkName: "component---src-components-activate-user-jsx" */),
  "component---src-components-create-password-jsx": () => import("./../../../src/components/createPassword.jsx" /* webpackChunkName: "component---src-components-create-password-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-forgotpassword-index-jsx": () => import("./../../../src/pages/forgotpassword/index.jsx" /* webpackChunkName: "component---src-pages-forgotpassword-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-index-jsx": () => import("./../../../src/pages/login/index.jsx" /* webpackChunkName: "component---src-pages-login-index-jsx" */),
  "component---src-pages-register-index-jsx": () => import("./../../../src/pages/register/index.jsx" /* webpackChunkName: "component---src-pages-register-index-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-subscription-index-jsx": () => import("./../../../src/pages/subscription/index.jsx" /* webpackChunkName: "component---src-pages-subscription-index-jsx" */),
  "component---src-pages-user-index-jsx": () => import("./../../../src/pages/user/index.jsx" /* webpackChunkName: "component---src-pages-user-index-jsx" */)
}

