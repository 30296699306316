import React, {
  createContext,
  useContext,
  useState
} from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage';

const defaultState = {
  isLoggedIn: false,
  setIsLoggedIn: () => null,
  customerData: null,
  setCustomerData: () => { },

  setCart: () => { },
  cart: undefined,
  cartStatus: {
    status: "",
    message: ""
  },
  cartRenewal: undefined,
  setCartRenewal: () => { },
  setCartStatus: () => { }
}

const AppContext = createContext(defaultState)

const AppProvider = ({ children }) => {
  const [cart, setCart] = useState(defaultState.cart)
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage(false)
  const [customerData, setCustomerData] = useState()
  const [cartStatus, setCartStatus] = useState(defaultState.cartStatus)
  const [cartRenewal, setCartRenewal] = useState(defaultState.cartRenewal)

  return (
    <AppContext.Provider value={{ cart, setCart, isLoggedIn, setIsLoggedIn, customerData, setCustomerData, cartStatus, setCartStatus, cartRenewal, setCartRenewal }}>
      {children}
    </AppContext.Provider>
  )
}

const useAppState = () => useContext(AppContext)

// type AppState = {
//   cart?: Cart
//   setCart: Dispatch<SetStateAction<Cart | undefined>>
// }

export { AppProvider, useAppState }
